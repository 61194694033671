import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Moment from 'moment-timezone'
import {
  deepCopy,
  isEmpty,
  checkOrderIsEditableNow,
  orderBalancesEditCutoffDate,
  roundUp,
} from '~/utils'
import {
  OrderItemsSection,
  ServiceItemsSection,
  VirtualItemsSection,
  VirtualKitsSection,
  SnackPacksSection,
} from './sections'
import { ChefNote, ItemPicker } from '@containers/order/edit/sections'
import Modal from '@components/common/modal/Modal'
import FlexContainer from '@components/common/FlexContainer'
import Dropdown from '@components/common/form/Dropdown'
import { EditOrderSection1 } from '@containers/order/edit'
import EditOrderSection2 from './EditOrderSection2'
import EditOrderSection3 from './EditOrderSection3'
import NonFinanceEditSection from './NonFinanceEditSection'
import DividerLine from '@components/common/DividerLine'
import Button from '@components/common/form/Button'
import {
  colors,
  DEFAULT_GRATUITY_TYPE_FIXED,
  DEFAULT_GRATUITY_TYPE_PERCENT,
  SECTION1,
  SECTION2,
  SECTION3,
  NONFINANCEEDIT,
} from '../../../../constants'
import { moneyString, isValidUrl } from '~/utils'
import { combineDateTime } from '@presenters/api/order'
import { CloseX } from '@res/styledComponents/index'

import { OrderablePropType } from './propTypes'
import { validateDinerProfileTags } from '@utils/menuItemUtils'

const CATERING_ORDER_TYPES = ['Lunch', 'Dinner', 'Dessert', 'Breakfast']

class EditOrderModal extends Component {
  isSaving = false

  state = {
    section: SECTION1,
    loadedChefItems: {},
    isLoadingChefItems: {},
    pickupWarningSeen: false,
    orderSettings: {},
  }

  componentDidMount() {
    const { orderable, section, user } = this.props

    // load async relational data
    this.props.clearErrors()
    this.props.loadReferralPartners('order')
    this.loadSettings()

    if (orderable.id) {
      this.props.loadHubspotDeal(orderable.id)
    }
    if (orderable.dinerProfileId) {
      this.loadDinerProfile(orderable.dinerProfileId)
    }

    this.loadAllChefItems()

    this.updateState({ creatorId: user && user.id }, undefined, false)

    // set default visible section
    this.setState({ section }, () => this.updateOrderSettings(orderable))
  }

  updateOrderSettings = ({
    clientSetUpTime,
    accountSettings = {},
    dinerProfileSettings = {},
  }) => {
    const {
      orderable: { isGratuityOverride, isStaffingFeeOverride },
      calcOrderSettings,
      updateOrderFromSettings,
    } = this.props

    const newSettings = calcOrderSettings({
      clientSetUpTime,
      accountSettings,
      dinerProfileSettings,
    })

    this.updateState(
      updateOrderFromSettings({
        orderSettings: newSettings,
        isGratuityOverride,
        isStaffingFeeOverride,
      }),
      () =>
        this.setState({
          orderSettings: newSettings,
        }),
    )
  }

  changePortions = ({
    numberOfEntrees = this.props.orderable.numberOfEntrees,
    headCount = this.props.orderable.headCount,
  }) => {
    const { delayedUpdateOrder, orderable } = this.props
    let { recommendedPortion } = orderable

    if (numberOfEntrees > 0 && headCount > 0) {
      let total = headCount / numberOfEntrees
      if (numberOfEntrees > 1) {
        total = Math.ceil(total * 1.2)
      }
      if (total < 8) {
        total = 8
      }
      recommendedPortion = total
    }
    delayedUpdateOrder({
      headCount,
      recommendedPortion,
      numberOfEntrees,
      serviceInputsChanged: true,
      routingInputsChanged: true,
    })
  }

  didConceptGetRemoved = () => {
    const { chefs, primaryChef } = this.props.orderable
    const otherChefs = chefs.map((c) =>
      c.orderMenuItems.filter(
        (o) => primaryChef && o.chefId === primaryChef.id,
      ),
    )

    return [].concat(...otherChefs).length === 1
  }

  getNewPrimaryChef = () => {
    const {
      orderable: { chefs, primaryChef },
      getEmptyChefs,
    } = this.props
    const emptyChefs = getEmptyChefs({ chefs })

    return chefs.filter(
      (c) =>
        !emptyChefs.some((emptyChef) => c.id === emptyChef.id) &&
        c.id !== (primaryChef && primaryChef.id),
    )[0]
  }

  updateServiceAndSupplyCosts = async (routingInputsChanged) => {
    const { orderable, user } = this.props
    let predictParams = { ...deepCopy(orderable), user: deepCopy(user) }
    if (!routingInputsChanged && orderable.orderServiceCost) {
      const {
        orderServiceCost: { routes },
      } = orderable
      predictParams = { ...predictParams, routes }
    }
    const result = await this.props.predictServiceCosts(predictParams)

    if (result) {
      const { serviceCosts, supplyBreakdown, rate, routes } = result
      this.updateState({
        predictedServiceCosts: serviceCosts,
        supplyBreakdown,
        orderServiceCost: { rate, routes, serviceCosts },
      })

      return true
    } else {
      return false
    }
  }

  loadSettings = async () => {
    const { delayedUpdateOrder } = this.props
    const { allCuisines } = await this.props.loadConceptsSettings()
    delayedUpdateOrder({
      ...this.props.orderable,
      allCuisines,
    })
  }

  loadDinerProfile = async (id) => {
    const {
      orderable: { clientSetUpTime, accountSettings, chefs },
      loadDinerProfile,
      pResponseCustomOrderSettings,
    } = this.props

    const profile = await loadDinerProfile(id)
    if (!profile) {
      return
    }
    if (profile.addChefNotes) {
      chefs.forEach((chef) => {
        if (!chef.chefNote) {
          chef.chefNote = {
            chefId: chef.id,
            chefName: chef.name,
            hungryDeliveryRequired: true,
            words: profile.chefNotes,
          }
        }
      })
    }

    const newState = {
      dinerProfile: profile,
      chefs: [...chefs],
    }
    if (profile.customOrderSettings) {
      newState.dinerProfileSettings = pResponseCustomOrderSettings(
        profile.customOrderSettings,
      )
    }
    this.updateState(
      newState,
      () =>
        this.updateOrderSettings({
          clientSetUpTime,
          accountSettings,
          dinerProfileSettings: newState.dinerProfileSettings,
        }),
      false,
    )
  }

  loadChefItems = async (chefId) => {
    const { loadedChefItems, isLoadingChefItems } = this.state
    if (loadedChefItems[chefId]) {
      return
    }

    this.setState({
      isLoadingChefItems: {
        ...isLoadingChefItems,
        [chefId]: true,
      },
    })

    const { loadFoodItems } = this.props
    const menuItems = await loadFoodItems(chefId)

    const newLoading = { ...isLoadingChefItems }
    delete newLoading[chefId]

    this.setState({
      loadedChefItems: {
        ...loadedChefItems,
        [chefId]: menuItems,
      },
      isLoadingChefItems: newLoading,
    })
  }

  loadAllChefItems = async () => {
    const {
      orderable: { chefs },
      loadFoodItems,
    } = this.props
    const chefIds = chefs.map((c) => c.id)

    this.setState({
      isLoadingChefItems: chefs.reduce((acc, chef) => {
        acc[chef.id] = true

        return acc
      }, {}),
    })

    await Promise.all(chefIds.map((chefId) => loadFoodItems(chefId))).then(
      (results) => {
        this.setState({
          loadedChefItems: results.reduce((acc, items, i) => {
            acc[chefIds[i]] = items

            return acc
          }, {}),
          isLoadingChefItems: {},
        })
      },
    )
  }

  /* set a did modify flag */
  updateState = (newState, callback = undefined, modified = undefined) => {
    const { clearError, delayedUpdateOrder } = this.props
    for (const key in newState) {
      clearError(key)
    }
    const didModify = modified !== undefined ? Boolean(modified) : true

    delayedUpdateOrder({ ...newState, didModify })
    callback && callback()
  }

  onChange = (newState, callback = undefined) => {
    this.updateState(newState, callback)
  }

  onSelectSetupDate = (clientSetUpTime) => {
    const { orderable } = this.props
    const { dinerProfileSettings, accountSettings } = orderable
    const newState = {
      serviceInputsChanged: true,
      routingInputsChanged: true,
    }

    if (!orderable?.clientSetUpTime) {
      clientSetUpTime.hour(12)
    } else {
      const hour = orderable.clientSetUpTime.hour()
      const minute = orderable.clientSetUpTime.minute()
      clientSetUpTime.hour(hour)
      clientSetUpTime.minute(minute)
    }

    newState['clientSetUpTime'] = clientSetUpTime
    newState['chargeDate'] = Moment(clientSetUpTime).hour(23).minute(30)

    if (orderable.clientDoNotArriveBeforeTime) {
      newState['clientDoNotArriveBeforeTime'] = combineDateTime(
        clientSetUpTime,
        orderable.clientDoNotArriveBeforeTime,
      )
    }
    this.updateState(newState, () =>
      this.updateOrderSettings({
        clientSetUpTime,
        accountSettings,
        dinerProfileSettings,
      }),
    )
  }

  onChangeAccountExecutive = (account, callback) => {
    const newState = {}
    newState['accountExecutive'] = account
    this.updateState(newState, callback)
  }

  onChangeAddress = (addressKey, callback) => (addressState) => {
    const newState = {}
    newState[addressKey] = addressState.dropoffAddress
    newState['new' + addressKey.charAt(0).toUpperCase() + addressKey.slice(1)] =
      addressState.newDropoffAddress
    newState['deliveryInstructions'] =
      addressState.dropoffAddress &&
      addressState.dropoffAddress.buildingInstructions
        ? addressState.dropoffAddress.buildingInstructions
        : ''
    if (addressKey === 'dropoffAddress') {
      newState.serviceInputsChanged = true
      newState.routingInputsChanged = true
    }
    this.updateState(newState, callback)
  }

  onChangePaymentMethod = (pmState) => {
    const { account } = this.props.orderable
    const { addresses } = account || {}
    const notAccountAddress =
      addresses &&
      !addresses.some((address) => address.id === pmState.billingAddressId)
    if (pmState.billingAddress && notAccountAddress) {
      pmState.account = {
        ...account,
        addresses: addresses.concat([pmState.billingAddress]),
      }
    }
    this.updateState(pmState)
  }

  onClearForm = () => {
    const { clearErrors, clearForm, delayedUpdateOrder } = this.props
    clearErrors()
    delayedUpdateOrder({ isPaid: false })
    clearForm()
  }

  onCreateCustomMenuItem = ({ chef, mealType }) => {
    let { chefs } = this.props.orderable
    const menuItem = {
      chefId: chef.id,
      mealType,
      itemType: 'MenuItem',
    }
    chefs = this.props.addMenuItemToChefs({
      chefs,
      chef,
      menuItem,
      isCustom: true,
    })

    this.updateState({ chefs })
  }

  onCreateCustomServiceItem = ({ chef }) => {
    if (!chef) {
      return
    }

    let { chefs } = this.props.orderable
    const serviceItem = {
      chefId: chef.id,
      itemType: 'ServiceItem',
    }
    chefs = this.props.addServiceItemToChefs({
      chefs,
      chef,
      serviceItem,
      isCustom: true,
    })

    this.updateState({ chefs })
  }

  onCreateCustomVirtualItem = ({ chef }) => {
    if (!chef) {
      return
    }

    let { chefs } = this.props.orderable
    const virtualItem = {
      chefId: chef.id,
      itemType: 'VirtualItem',
    }
    chefs = this.props.addVirtualItemToChefs({
      chefs,
      chef,
      virtualItem,
      isCustom: true,
    })

    this.updateState({ chefs })
  }

  onDeleteReferralPartner = () => {
    const { delayedUpdateOrder, orderable } = this.props
    const { referredBy } = orderable
    if (!referredBy || isEmpty(referredBy)) {
      return
    }
    const deletedRef = { ...referredBy }
    deletedRef['_destroy'] = true
    delayedUpdateOrder({ referredBy: { ...deletedRef } })
  }

  onEditChefNote = (chefNote) => {
    let { chefs } = this.props.orderable
    chefs = this.props.replaceChefNoteInAddOnChefs({ chefs, chefNote })
    this.updateState({ chefs })
  }

  onEditOrderItem = (isCustom, itemType) => (orderItem) => {
    const {
      replaceOrderMenuItemInChefs,
      replaceOrderServiceItemInChefs,
      replaceOrderSnackPackInChefs,
      replaceOrderVirtualItemInChefs,
      replaceOrderVirtualKitInChefs,
    } = this.props

    let { chefs } = this.props.orderable
    if (itemType === 'MenuItem') {
      chefs = replaceOrderMenuItemInChefs({ chefs, orderItem, isCustom })
    } else if (itemType === 'ServiceItem') {
      chefs = replaceOrderServiceItemInChefs({ chefs, orderItem, isCustom })
    } else if (itemType === 'VirtualItem') {
      chefs = replaceOrderVirtualItemInChefs({ chefs, orderItem, isCustom })
    } else if (itemType === 'VirtualKit') {
      chefs = replaceOrderVirtualKitInChefs({ chefs, orderItem, isCustom })
    } else if (itemType === 'SnackPack') {
      chefs = replaceOrderSnackPackInChefs({ chefs, orderItem, isCustom })
    }
    this.updateState({ chefs })
  }

  onHide = () => {
    this.props.close()
  }

  onRemoveOrderItem = (isCustom, itemType) => (orderItem) => {
    const { orderable } = this.props
    let { chefs } = orderable
    if (itemType === 'MenuItem') {
      chefs = this.props.removeOrderMenuItemFromChefs({
        chefs,
        orderItem,
        isCustom,
      })
    } else if (itemType === 'ServiceItem') {
      chefs = this.props.removeOrderServiceItemFromChefs({
        chefs,
        orderItem,
        isCustom,
      })
    } else if (itemType === 'VirtualItem') {
      chefs = this.props.removeOrderVirtualItemFromChefs({
        chefs,
        orderItem,
        isCustom,
      })
    } else if (itemType === 'VirtualKit') {
      chefs = this.props.removeOrderVirtualKitFromChefs({
        chefs,
        orderItem,
        isCustom,
      })
    } else if (itemType === 'SnackPack') {
      chefs = this.props.removeOrderSnackPackFromChefs({
        chefs,
        orderItem,
        isCustom,
      })
    }

    this.updateState({ chefs }, () =>
      this.updateRemovedOrEmptyChefs({ ...orderable, chefs }),
    )
  }

  onReorderItem = (isCustom, itemType) => (orderItem, change) => {
    let { chefs } = this.props.orderable
    chefs = this.props.reorderItemInChefs({
      chefs,
      orderItem,
      change,
      isCustom,
      itemType,
    })

    this.updateState({ chefs })
  }

  onReorderChef = (chef, change) => {
    let { chefs } = this.props.orderable
    chefs = this.props.reorderChef({ chefs, chef, change })

    this.updateState({ chefs })
  }

  onCheckCarbonNeutral = (e) => {
    const { delayedUpdateOrder, orderable } = this.props
    const { checked } = e.target
    if (!checked) {
      delayedUpdateOrder({
        carbonNeutral: false,
        carbonNeutralContribution: 0,
      })
    } else {
      delayedUpdateOrder({
        carbonNeutral: true,
        carbonNeutralContribution:
          orderable.account.carbonNeutralContribution || 0,
      })
    }
  }

  flashMessageCallback = (order) => {
    const { uri } = this.props
    window.open(`${uri}/sales/?order_id=${order.id}`)
  }

  checkBalancesChanged = () => {
    const { calculateAll, calculateDiscounts, orderable } = this.props
    const { serviceFee, subtotal, tax, total } = calculateAll(orderable)
    const { discountAmount, discountType, discounts, initialBalances, tip } =
      orderable
    const discountValue = calculateDiscounts({
      discountAmount,
      discountType,
      discounts,
      subtotal,
    })

    const nextBalances = {
      subtotal,
      serviceFee,
      tax,
      tip,
      discounts: discountValue,
      total,
    }
    if (
      roundUp(initialBalances.subtotal || 0) !==
        roundUp(nextBalances.subtotal || 0) ||
      roundUp(initialBalances.serviceFee || 0) !==
        roundUp(nextBalances.serviceFee || 0) ||
      roundUp(initialBalances.tax || 0) !== roundUp(nextBalances.tax || 0) ||
      roundUp(initialBalances.tip || 0) !== roundUp(nextBalances.tip || 0) ||
      roundUp(initialBalances.discounts || 0) !==
        roundUp(nextBalances.discounts || 0) ||
      roundUp(initialBalances.total || 0) !== roundUp(nextBalances.total || 0)
    ) {
      return true
    }

    return false
  }

  validateEventServiceItems = (flashMsg) => {
    const {
      orderable: { chefs },
    } = this.props

    const anySvcItemsUncatted = chefs.some((chef) => {
      const { orderServiceItems, customOrderServiceItems } = chef

      return (
        orderServiceItems.some(
          ({ serviceItemCategory }) =>
            !serviceItemCategory || serviceItemCategory === '',
        ) ||
        customOrderServiceItems.some(
          ({ serviceItemCategory }) =>
            !serviceItemCategory || serviceItemCategory === '',
        )
      )
    })

    if (anySvcItemsUncatted) {
      flashMsg(
        'All service items on an Event Proposal must have a category to ensure proper rendering of the Event Proposal PDF.',
      )

      return false
    }

    return true
  }

  onSave = async () => {
    const {
      loadOrders,
      loadProposalDashboard,
      saveOrder,
      userPermissions,
      displayFailureMessage,
      displayWarningMessage,
      delayedUpdateOrder,
      headquarterId,
      orderable,
      showConfirmationModal,
      validateChefPickupTimes,
      user: { locale },
    } = this.props
    const {
      id,
      orderableType,
      orderType,
      beoLink,
      clientSetUpTime,
      isTaxExempt,
      taxWarningSeen,
      taxRates,
      deliveryTaxRates,
      servicesTaxRates,
      carbonNeutral,
      carbonNeutralContribution,
      chefs,
      dinerProfile,
    } = orderable
    const { pickupWarningSeen } = this.state
    const isProposal = orderableType === 'Proposal'
    const isEvent = orderType === 'Event'

    const hasMasterEditBalancesRole = userPermissions.some(
      (permission) => permission === 'admin' || permission === 'finance',
    )
    const isBeforeEditingCutoff = checkOrderIsEditableNow(clientSetUpTime)
    const editCutoff = orderBalancesEditCutoffDate(clientSetUpTime)
    const isBalancesChanged = Boolean(id && this.checkBalancesChanged())
    if (
      !isProposal &&
      isBalancesChanged &&
      !isBeforeEditingCutoff &&
      !hasMasterEditBalancesRole
    ) {
      displayFailureMessage(
        `Cannot modify order balances after ${editCutoff.format(
          'MMMM Do YYYY, h:mm:ss a',
        )}, the monday of the event date. Please reach out to the support team for assistance.`,
      )

      return
    }

    if (isProposal && isEvent) {
      if (!this.validateEventServiceItems(displayFailureMessage)) {
        return
      }
    }

    if (!isProposal && isEvent && !isValidUrl(beoLink)) {
      displayFailureMessage(
        'Missing or invalid Event Checklist link on the Event Order. Please add your Event Checklist/BEO link to the order on the Order Details page then you will be able to book the order.',
      )

      return
    }

    const hasTaxRates = !(
      taxRates == null &&
      deliveryTaxRates == null &&
      servicesTaxRates == null
    )
    const { tax } = this.props.calculateAll(this.props.orderable)
    if (!hasTaxRates) {
      displayFailureMessage(
        'No tax rates found. Please wait for tax to finish calculating before saving.',
      )

      return
    } else if (hasTaxRates && tax === 0) {
      if (!taxWarningSeen) {
        displayWarningMessage(
          `This order has ${tax} tax. Please confirm this is correct before attempting to save again.`,
        )
        delayedUpdateOrder({ taxWarningSeen: true })

        return
      }
    } else if (hasTaxRates && isTaxExempt && tax !== 0) {
      //Edge case when is tax exempt but is above 0.
      displayFailureMessage(
        'Order is tax exempt and the tax value is not currently 0. Please reach out to tech@tryhungry.com for assistance',
      )

      return
    }
    const isFutureOrder = Moment(clientSetUpTime).isAfter(Moment())
    if (dinerProfile && isFutureOrder) {
      // validate each chefs order menu items against dietary tags
      const allItems = chefs.reduce((allItems, chef) => {
        const chefMenuItems = (chef.orderMenuItems || []).reduce(
          (items, item) => {
            if (item.menuItem) {
              items.push(item.menuItem)
            } else {
              items.push(item)
            }

            return items
          },
          [],
        )
        allItems.push(...chefMenuItems)

        return allItems
      }, [])

      const { includeWarningTags, excludeWarningTags } =
        validateDinerProfileTags(allItems, dinerProfile)
      if (includeWarningTags.length > 0 || excludeWarningTags.length > 0) {
        const msg = `This ${orderableType} does not contain menu items that meet the requirements of the Diner Profile used: ${
          includeWarningTags.length > 0
            ? `Must include: ${includeWarningTags.join(', ')}`
            : ''
        }${
          includeWarningTags.length > 0 && excludeWarningTags.length > 0
            ? ' | '
            : ''
        }${
          excludeWarningTags.length > 0
            ? `Must exclude: ${excludeWarningTags.join(', ')}`
            : ''
        }. Are you sure you want to proceed saving this ${orderableType}?`

        const confirmed = await showConfirmationModal({
          text: msg,
        })
        if (!confirmed) {
          return
        }
      }
    }

    const chefMinPayoutErrors = this.getChefMinPayoutErrors()
    if (chefMinPayoutErrors) {
      const errorString = chefMinPayoutErrors.join(' | ')
      const msg = `Order does not meet required minimum payout for selected chef(s): ${errorString}`
      displayFailureMessage(msg)

      return
    }

    if (carbonNeutral && carbonNeutralContribution === 0) {
      displayFailureMessage(
        'Cannot mark order carbon neutral without a contribution amount',
      )

      return
    }

    if (
      !pickupWarningSeen &&
      CATERING_ORDER_TYPES.includes(orderType) &&
      isFutureOrder // Validate chef pickup times only if it's a future order
    ) {
      const pickupTimesConfirmed = await validateChefPickupTimes({
        orderType,
        orderSetupTime: Moment(clientSetUpTime).tz(locale).format(),
        chefIds: chefs.map((c) => c.id),
        headquarterId,
      })
      if (!pickupTimesConfirmed) {
        return
      } else {
        this.setState({
          pickupWarningSeen: true,
        })
      }
    }

    if (!this.isSaving) {
      this.isSaving = true
      await saveOrder(orderable, this.flashMessageCallback)
      loadOrders()
      if (isProposal) {
        loadProposalDashboard()
      }
      this.isSaving = false
    }
  }

  getChefMinPayoutErrors = () => {
    const { getChefAmountMap } = this.props
    const { chefs, adjustedChefPayouts, status } = this.props.orderable

    const chefAmountMap = getChefAmountMap({
      chefs,
      adjustedChefPayouts,
    })

    const errors = []

    chefs.forEach((chef) => {
      if (
        adjustedChefPayouts &&
        adjustedChefPayouts.find((a) => a.id === chef.id)
      ) {
        return
      }
      const chefAmount = chefAmountMap[chef.id] || 0
      let minPayout = 0
      // NOTE: because chef data shape is not always
      // consistent (depends if this is a new or existing order),
      // check for minCateringPayout on chef and chef profile
      if (chef.minCateringPayout) {
        minPayout = chef.minCateringPayout
      } else if (chef.chefProfile && chef.chefProfile.minCateringPayout) {
        minPayout = chef.chefProfile.minCateringPayout
      }
      if (status !== 'canceled' && chefAmount < minPayout) {
        const msg = `Chef ${chef.name} requires minimum of $${moneyString(
          minPayout,
        )}; current value: $${moneyString(chefAmount)} `
        errors.push(msg)
      }
    })

    return errors.length ? errors : null
  }

  onSaveAccount = () => {
    const { updateTaxExemptStatus, orderable } = this.props
    ;(async () => {
      const account = await this.props.saveAccount({ data: orderable })
      const newState = { account, newAccount: {} }
      updateTaxExemptStatus({ account, orderable, newState })
      this.updateState(newState)
    })()
  }

  onSaveAddress = (addressKey) => async () => {
    const newAddressKey =
      'new' + addressKey.charAt(0).toUpperCase() + addressKey.slice(1)
    const { saveAddress, validateAddress } = this.props
    let { account, [newAddressKey]: address } = this.props.orderable

    if (validateAddress(address)) {
      ;({ account, address } = await saveAddress({ account, address }))

      const newState = { account }
      newState[addressKey] = address
      newState[newAddressKey] = {}
      newState['deliveryInstructions'] =
        address && address.buildingInstructions
          ? address.buildingInstructions
          : ''
      this.updateState(newState)

      return true
    }

    return false
  }

  onSaveContact = async (contactType = '') => {
    const {
      pContactKeys,
      saveContact,
      updateTaxExemptStatus,
      validateContact,
      orderable,
    } = this.props
    let { account } = orderable
    const { key, newKey } = pContactKeys(contactType)
    let contact = orderable[newKey]

    if (validateContact(contact)) {
      ;({ account, contact } = await saveContact({ account, contact }))
      if (!account && !contact) {
        return
      }

      const newState = {
        account,
        [newKey]: {},
      }
      if (contactType) {
        // specifically set contact if of type 'invoice', 'receipt', or 'catering'
        newState[key] = contact
      } else {
        // set all if first ever contact
        newState.invoiceContact = contact
        newState.receiptContact = contact
        newState.cateringContact = contact
      }
      if (!contactType || contactType === 'invoice') {
        updateTaxExemptStatus({ contact, account, orderable, newState })
      }
      this.updateState(newState)

      return true
    }

    return false
  }

  onSavePaymentMethod = async () => {
    const { savePaymentMethod, validatePaymentMethod, orderable } = this.props
    let { account, newPaymentMethod: paymentMethod } = orderable

    if (validatePaymentMethod(paymentMethod)) {
      ;({ account, paymentMethod } = await savePaymentMethod({
        account,
        paymentMethod,
      }))
      this.updateState({
        account,
        paymentMethod,
        newPaymentMethod: {},
      })

      return true
    }

    return false
  }

  onSelectAccount = (account) => {
    ;(async () => {
      const {
        orderable,
        loadAccount,
        pDefaultAccountFields,
        pAccountSettingsFromAccount,
        updateTaxExemptStatus,
      } = this.props
      const {
        id,
        accountSettings: oAccountSettings,
        dinerProfileSettings,
        clientSetUpTime,
      } = orderable
      let accountSettings = oAccountSettings
      if (account) {
        this.updateState({ newAccount: {} })

        if (account.id) {
          account = await loadAccount(account.id)
        }
        const newState = pDefaultAccountFields(account)
        updateTaxExemptStatus({ account, orderable, newState })
        // TODO: Move this from here
        if (!id) {
          if (newState.account) {
            const {
              cateringStaffCount,
              cateringStaffFee,
              cateringStaffHours,
              cateringStaffRate,
              needStaffAndServe,
              setUpCompleteByTime,
              doNotArriveBeforeTime,
              gratuity,
              gratuityType,
            } = newState.account
            newState.needsStaffing = needStaffAndServe
            if (setUpCompleteByTime) {
              newState.clientSetUpTime = setUpCompleteByTime
            }
            if (doNotArriveBeforeTime) {
              newState.clientDoNotArriveBeforeTime = doNotArriveBeforeTime
            }
            if (cateringStaffCount) {
              newState.numberOfStaff = cateringStaffCount
            }
            if (cateringStaffHours) {
              newState.staffingHours = cateringStaffHours
            }
            if (cateringStaffFee) {
              newState.staffingFee = cateringStaffFee
            }
            if (cateringStaffRate) {
              newState.staffingRate = cateringStaffRate
            }
            if (gratuity && gratuityType) {
              if (gratuityType === DEFAULT_GRATUITY_TYPE_FIXED) {
                newState.tip = gratuity
              } else if (gratuityType === DEFAULT_GRATUITY_TYPE_PERCENT) {
                newState.tip = 0
                newState.defaultTipPercent = gratuity
              }
            }

            const newAccountSettings = pAccountSettingsFromAccount(
              newState.account,
            )
            newState.accountSettings = newAccountSettings
            accountSettings = newAccountSettings
          }
        }
        newState.carbonNeutral = account.carbonNeutral
        newState.carbonNeutralContribution = account.carbonNeutralContribution

        this.updateState(newState, () =>
          this.updateOrderSettings({
            clientSetUpTime,
            accountSettings,
            dinerProfileSettings,
          }),
        )
      } else {
        const newState = { account }
        updateTaxExemptStatus({ account, orderable, newState })
        newState.carbonNeutral = account.carbonNeutral
        newState.carbonNeutralContribution = account.carbonNeutralContribution
        this.updateState(newState)
      }
    })()
  }

  onSelectDinerProfile = (dinerProfile) => {
    const {
      orderable,
      pOrderAttributesFromDinerProfile,
      pResponseCustomOrderSettings,
      hqLocaleMap,
    } = this.props
    const { account, chefs, accountSettings, clientSetUpTime } = orderable

    // non-fee related values
    const dinerProfileAttributes = pOrderAttributesFromDinerProfile(
      hqLocaleMap,
      dinerProfile,
      account.contacts,
      account.addresses,
      orderable.clientSetUpTime,
    )
    if (dinerProfile.addChefNotes) {
      chefs.forEach((chef) => {
        if (!chef.chefNote) {
          chef.chefNote = {
            words: dinerProfile.chefNotes,
            chefId: chef.id,
            chefName: chef.name,
            hungryDeliveryRequired: true,
          }
        }
      })
    }

    const nextState = {
      ...dinerProfileAttributes,
      chefs: [...chefs],
    }
    if (dinerProfile.customOrderSettings) {
      nextState.dinerProfileSettings = pResponseCustomOrderSettings(
        dinerProfile.customOrderSettings,
      )
    }

    this.updateState(nextState, () =>
      this.updateOrderSettings({
        clientSetUpTime,
        accountSettings,
        dinerProfileSettings: nextState.dinerProfileSettings,
      }),
    )
  }

  onSelectMenuItem =
    (chef) =>
    (menuItem, selectedConceptId = null, newConceptName = null) => {
      let { chefs, conceptId, conceptName, primaryChef } = this.props.orderable
      chefs = this.props.addMenuItemToChefs({
        chefs,
        chef,
        menuItem,
        isCustom: false,
      })
      if (selectedConceptId) {
        conceptId = selectedConceptId
        primaryChef = chef
      }
      if (newConceptName) {
        conceptName = newConceptName
      }
      if (!this.props.orderable.chefStatuses[chef.id]) {
        this.setDefaultChefStatuses(chef.id)
      }
      this.updateState({ chefs, conceptName, conceptId, primaryChef })
    }

  onSelectReferralPartner = (e) => {
    const { delayedUpdateOrder, orderable } = this.props
    const { referralPartners, referredBy } = orderable
    const resourceId = e.target.value
    if (resourceId.length > 0) {
      const refPartner = referralPartners.find((r) => r.id === resourceId)
      const newSelRef = { ...referredBy }
      newSelRef.contactId = refPartner.id
      delayedUpdateOrder({ referredBy: newSelRef })
    } else {
      delayedUpdateOrder({ referredBy: null })
    }
  }

  onSelectServiceItem = (chef) => (serviceItem) => {
    const { orderable, displayWarningMessage } = this.props
    const { orderableType, orderType } = orderable
    const isProposal = orderableType === 'Proposal'
    const isEvent = orderType === 'Event'

    if (isProposal && isEvent) {
      const { name, serviceItemCategory } = serviceItem
      if (!serviceItemCategory || serviceItemCategory === '') {
        displayWarningMessage(
          `Service item ${name} must have a category to ensure proper rendering of the Event Proposal PDF.`,
        )
      }
    }

    let { chefs } = orderable
    chefs = this.props.addServiceItemToChefs({
      chefs,
      chef,
      serviceItem,
      isCustom: false,
    })

    this.updateState({ chefs })
  }

  onSelectVirtualItem = (chef) => (virtualItem) => {
    let { chefs } = this.props.orderable
    chefs = this.props.addVirtualItemToChefs({
      chefs,
      chef,
      virtualItem,
      isCustom: false,
    })
    if (!this.props.orderable.chefStatuses[chef.id]) {
      this.setDefaultChefStatuses(chef.id)
    }

    this.updateState({ chefs })
  }

  onSelectVirtualKit = (chef) => (virtualKit) => {
    let { chefs } = this.props.orderable
    chefs = this.props.addVirtualKitToChefs({
      chefs,
      chef,
      virtualKit,
      isCustom: false,
    })
    if (!this.props.orderable.chefStatuses[chef.id]) {
      this.setDefaultChefStatuses(chef.id)
    }

    this.updateState({ chefs })
  }

  onSelectSnackPack = (chef) => (snackPack) => {
    let { chefs } = this.props.orderable
    chefs = this.props.addSnackPackToChefs({
      chefs,
      chef,
      snackPack,
      isCustom: false,
    })
    if (!this.props.orderable.chefStatuses[chef.id]) {
      this.setDefaultChefStatuses(chef.id)
    }

    this.updateState({ chefs })
  }

  loadConcepts = async (chefId, orderType) => {
    const concepts = await this.props.loadConcepts(chefId, orderType)
    this.updateState({ concepts })
  }

  loadVirtualKits = async (chefId) => {
    const virtualKits = await this.props.loadChefVirtualKits(chefId)
    this.updateState({ virtualKits })
  }

  loadSnackPack = async (chefId) => {
    const snackPacks = await this.props.loadChefSnackPacks(chefId)
    this.updateState({ snackPacks })
  }

  setDefaultChefStatuses = (chefId) => {
    const { chefStatuses } = this.props.orderable
    chefStatuses[chefId] = {
      isAccepted: -1,
      declineReason: '',
      cnStatus: false,
    }
    this.updateState({ chefStatuses })
  }

  removeChefStatus = (chefId) => {
    const { chefStatuses } = this.props.orderable
    delete chefStatuses[chefId]
    this.updateState({ chefStatuses })
  }

  onDeleteChef = (chef) => {
    this.onRemoveChef(chef)
    this.navigateToSection(SECTION2)
  }

  onRemoveChef = (chef) => {
    const { orderable, removeChefFromChefs } = this.props
    let { chefs } = orderable

    const nextChefItems = { ...this.state.loadedChefItems }
    delete nextChefItems[chef.id]
    this.setState({ loadedChefItems: nextChefItems })
    this.removeChefStatus(chef.id)

    chefs = removeChefFromChefs({ chefs, chef })

    this.updateState(
      {
        chefs,
        serviceInputsChanged: true,
        routingInputsChanged: true,
      },
      () => this.updateRemovedOrEmptyChefs({ ...orderable, chefs }),
    )
  }

  // when clearing chef items but not removing chef
  updateRemovedOrEmptyChefs = ({ chefs, primaryChef, adjustedChefPayouts }) => {
    const { getEmptyChefs } = this.props
    const emptyChefs = getEmptyChefs({ chefs })
    emptyChefs.forEach((chef) => this.removeChefStatus(chef.id))

    const nextState = {
      adjustedChefPayouts:
        adjustedChefPayouts &&
        adjustedChefPayouts.filter(
          (p) =>
            chefs.some((c) => c.id === p.id) &&
            !emptyChefs.some((c) => c.id === p.id),
        ),
    }
    if (
      !primaryChef ||
      emptyChefs.some((c) => c.id === primaryChef.id) ||
      !chefs.some((c) => c.id === primaryChef.id)
    ) {
      nextState.primaryChef = this.getNewPrimaryChef()
      nextState.conceptId = null
      nextState.conceptName = null
    }

    this.updateState(nextState)
  }

  onDeleteAllChefs = () => {
    this.navigateToSection(SECTION1)
    this.updateState(
      {
        chefs: [],
        chefStatuses: {},
        serviceInputsChanged: true,
        routingInputsChanged: true,
      },
      () =>
        this.setState({
          skippedBulkGeneration: false,
          loadedChefItems: {},
        }),
    )
  }

  onSelectChef = async (chef) => {
    let { chefs } = this.props.orderable
    const { dinerProfile } = this.props.orderable
    const beforeNumChefs = chefs.length
    chefs = this.props.addCheftoChefs(
      { chefs, chef },
      dinerProfile?.addChefNotes ? dinerProfile.chefNotes : undefined,
    )
    const afterNumChefs = chefs.length
    this.setDefaultChefStatuses(chef.id)
    await this.loadChefItems(chef.id)
    const newState = { chefs }
    if (beforeNumChefs !== afterNumChefs) {
      newState.serviceInputsChanged = true
      newState.routingInputsChanged = true
    }
    this.updateState(newState)
  }

  onSelectContacts = (contacts) => {
    const { account } = this.props.orderable
    const newState = { account, ...contacts }
    const invoiceContact = contacts && contacts.invoiceContact
    this.props.updateTaxExemptStatus({
      contact: invoiceContact,
      orderable: this.props.orderable,
      account,
      newState,
    })
    this.updateState(newState)
  }

  onSelectOrderType = (orderType) => {
    this.updateState({
      orderType,
    })
  }

  onSelectHubspotProposal = (p) => {
    const { orderable, delayedUpdateOrder } = this.props
    delayedUpdateOrder({ ...orderable, hubspotProposalId: p.id })
  }

  onUndeleteReferralPartner = () => {
    const { delayedUpdateOrder, orderable } = this.props
    const { referredBy } = orderable
    const ref = { ...referredBy }
    ref._destroy = false
    delayedUpdateOrder({ referredBy: ref })
  }

  renderReOrder = (isFirst, isLast, change, chef) => {
    let symbol
    if (change > 0 && !isLast) {
      symbol = '▼'
    } else if (change < 0 && !isFirst) {
      symbol = '▲'
    }

    if (symbol) {
      return (
        <span
          className="pointer w-2"
          onClick={() => this.onReorderChef(chef, change)}
        >
          {' '}
          {symbol}{' '}
        </span>
      )
    }
  }

  renderChef = ({ chef, isFirst, isLast }) => {
    const { syncChildItems } = this.props
    const { primaryChef, orderType } = this.props.orderable

    const title =
      primaryChef && chef.id === primaryChef.id ? 'Primary Chef' : 'Chef'
    const name = `${title} ${chef.name}`
    const isVCX = orderType === 'VCX'
    const isSnackPack = orderType === 'Snack Pack'

    const { loadedChefItems, isLoadingChefItems } = this.state

    return (
      <div key={chef.id} className="mb-4">
        <FlexContainer
          flexDirection="row"
          alignItems="center"
          padding="20px 0 0 10px"
        >
          <div className="flex flex-col mx-5 justify-center">
            {this.renderReOrder(isFirst, isLast, -1, chef)}
            {this.renderReOrder(isFirst, isLast, 1, chef)}
          </div>
          <CloseX color={colors.blue} onClick={() => this.onRemoveChef(chef)}>
            ✕
          </CloseX>
          <p className="ml-2 text-lg bold text-blue-600">{name}</p>
        </FlexContainer>

        {!isVCX && !isSnackPack && (
          <OrderItemsSection
            key={chef.id}
            chefName={chef.name}
            customOrderItems={chef.customOrderMenuItems}
            orderItems={chef.orderMenuItems}
            onEditCustomOrderMenuItem={this.onEditOrderItem(true, 'MenuItem')}
            onEditOrderMenuItem={this.onEditOrderItem(false, 'MenuItem')}
            onRemoveCustomOrderMenuItem={this.onRemoveOrderItem(
              true,
              'MenuItem',
            )}
            onRemoveOrderMenuItem={this.onRemoveOrderItem(false, 'MenuItem')}
            onReorderCustomMenuItem={this.onReorderItem(true, 'MenuItem')}
            onReorderMenuItem={this.onReorderItem(false, 'MenuItem')}
            syncChildItems={syncChildItems}
          />
        )}
        {!isVCX && !isSnackPack && (
          <ServiceItemsSection
            orderServiceItems={chef.orderServiceItems}
            customOrderServiceItems={chef.customOrderServiceItems}
            onEditOrderServiceItem={this.onEditOrderItem(false, 'ServiceItem')}
            onEditCustomOrderServiceItem={this.onEditOrderItem(
              true,
              'ServiceItem',
            )}
            onRemoveOrderServiceItem={this.onRemoveOrderItem(
              false,
              'ServiceItem',
            )}
            onRemoveCustomOrderServiceItem={this.onRemoveOrderItem(
              true,
              'ServiceItem',
            )}
            onReorderCustomServiceItem={this.onReorderItem(true, 'ServiceItem')}
            onReorderServiceItem={this.onReorderItem(false, 'ServiceItem')}
          />
        )}
        {
          <VirtualItemsSection
            orderVirtualItems={chef.orderVirtualItems}
            customOrderVirtualItems={chef.customOrderVirtualItems}
            onEditOrderVirtualItem={this.onEditOrderItem(false, 'VirtualItem')}
            onEditCustomOrderVirtualItem={this.onEditOrderItem(
              true,
              'VirtualItem',
            )}
            onRemoveOrderVirtualItem={this.onRemoveOrderItem(
              false,
              'VirtualItem',
            )}
            onRemoveCustomOrderVirtualItem={this.onRemoveOrderItem(
              true,
              'VirtualItem',
            )}
            onReorderCustomVirtualItem={this.onReorderItem(true, 'VirtualItem')}
            onReorderVirtualItem={this.onReorderItem(false, 'VirtualItem')}
          />
        }
        {isVCX && (
          <VirtualKitsSection
            orderVirtualKits={chef.orderVirtualKits}
            onEditOrderVirtualKit={this.onEditOrderItem(false, 'VirtualKit')}
            onRemoveOrderVirtualKit={this.onRemoveOrderItem(
              false,
              'VirtualKit',
            )}
            onReorderVirtualKit={this.onReorderItem(false, 'VirtualKit')}
          />
        )}
        {isSnackPack && (
          <SnackPacksSection
            orderSnackPacks={chef.orderSnackPacks}
            onEditOrderSnackPack={this.onEditOrderItem(false, 'SnackPack')}
            onRemoveOrderSnackPack={this.onRemoveOrderItem(false, 'SnackPack')}
            onReorderSnackPack={this.onReorderItem(false, 'SnackPack')}
          />
        )}
        <ItemPicker
          chef={chef}
          orderType={orderType}
          menuItems={loadedChefItems[chef.id]}
          itemsLoading={isLoadingChefItems[chef.id]}
          onSelectMenuItem={this.onSelectMenuItem(chef)}
          onSelectServiceItem={this.onSelectServiceItem(chef)}
          onSelectVirtualItem={this.onSelectVirtualItem(chef)}
          onSelectVirtualKit={this.onSelectVirtualKit(chef)}
          onSelectSnackPack={this.onSelectSnackPack(chef)}
          onCreateCustomMenuItem={this.onCreateCustomMenuItem}
          onCreateCustomServiceItem={this.onCreateCustomServiceItem}
          onCreateCustomVirtualItem={this.onCreateCustomVirtualItem}
        />
        <ChefNote
          chefName={chef.name}
          chefNote={chef.chefNote}
          chefId={chef.id}
          onEditChefNote={this.onEditChefNote}
        />
        <Button
          testId="delete"
          label={'〈 Delete all items & change vendor'}
          onClick={() => this.onDeleteChef(chef)}
          backgroundColor={colors.orange}
        />
        <DividerLine margin="15px 0" />
      </div>
    )
  }

  renderReferralDeleted = () => {
    const { referredBy, referralPartners } = this.props.orderable

    if (!referredBy || (!referredBy._destroy && referredBy._destroy !== true)) {
      return null
    }

    const contact = referralPartners.find((r) => r.id === referredBy.contactId)

    return (
      <div className="form-field-container__columns delete-list">
        <p className="flex-align-top">Marked for Deletion</p>
        <div>
          <div className="columns-container">
            ―<span> {contact && contact.name}</span>
            <button
              className="cancel-button"
              id="selected-referral-partner-unmark-delete"
              onClick={this.onUndeleteReferralPartner}
            >
              Unmark Delete
            </button>
          </div>
        </div>
      </div>
    )
  }

  renderReferralDropDown = () => {
    const { referralPartners, orderableType, id, referredBy } =
      this.props.orderable
    const { contactId } = referredBy || {}

    return (
      <Dropdown
        label="Referred By"
        width={orderableType === 'Order' && id ? '31%' : '48%'}
        value={contactId}
        onChange={this.onSelectReferralPartner}
      >
        <option value="">No Referral</option>
        {referralPartners.map((r) => (
          <option key={r.id} value={r.id} selected={r.id === contactId}>
            {r.name}
          </option>
        ))}
      </Dropdown>
    )
  }

  saveOrderRoles = () => {
    const { orderable } = this.props

    const { orderableType, clientSetUpTime } = orderable || {}

    const saveOrderRoles = ['sales lead', 'master admin', 'chef lead']
    if (
      orderableType === 'Proposal' ||
      (clientSetUpTime &&
        Moment(clientSetUpTime).add(10, 'days').isAfter(Moment()))
    ) {
      saveOrderRoles.push('sales rep')
    }

    return saveOrderRoles
  }

  navigateToSection = (section) => {
    this.setState({ section })
  }

  renderSection1() {
    const {
      orderable,

      loadAccountExecutives,
      loadHubspotProposals,
      confirmPaymentMethod,

      showConfirmationModal,

      displayWarningMessage,

      clearError,
      errors,
    } = this.props
    const { skippedBulkGeneration } = this.state
    const { id, orderableType } = orderable
    const isProposal = orderableType === 'Proposal'

    return (
      <EditOrderSection1
        orderable={orderable}
        changePortions={this.changePortions}
        loadAccountExecutives={loadAccountExecutives}
        loadHubspotProposals={loadHubspotProposals}
        renderReferralDropDown={this.renderReferralDropDown}
        skippedBulkGeneration={skippedBulkGeneration}
        onChange={this.onChange}
        onChangeAccountExecutive={this.onChangeAccountExecutive}
        onSelectSetupDate={this.onSelectSetupDate}
        onChangeAddress={this.onChangeAddress}
        onChangePaymentMethod={this.onChangePaymentMethod}
        onCheckCarbonNeutral={this.onCheckCarbonNeutral}
        onSaveAccount={this.onSaveAccount}
        onSaveAddress={this.onSaveAddress}
        onSaveContact={this.onSaveContact}
        onSavePaymentMethod={this.onSavePaymentMethod}
        onSelectAccount={this.onSelectAccount}
        onSelectDinerProfile={this.onSelectDinerProfile}
        onSelectContacts={this.onSelectContacts}
        onSelectHubspotProposal={this.onSelectHubspotProposal}
        onSelectOrderType={this.onSelectOrderType}
        onSave={this.onSave}
        onHide={this.onHide}
        section={SECTION1}
        nextSection={
          isProposal && !id && !skippedBulkGeneration ? SECTION2 : SECTION3
        }
        customCreateSection={SECTION3}
        navigateToSection={this.navigateToSection}
        clearError={clearError}
        errors={errors}
        saveOrderRoles={this.saveOrderRoles()}
        confirmPaymentMethod={confirmPaymentMethod}
        showConfirmationModal={showConfirmationModal}
        validateEventServiceItems={() =>
          this.validateEventServiceItems(displayWarningMessage)
        }
        updateSkippedState={(val) =>
          this.setState({ skippedBulkGeneration: val })
        }
        updateOrderSettings={this.updateOrderSettings}
      />
    )
  }

  renderSection2() {
    const { orderable, loadTaxRates, calculateAll } = this.props

    return (
      <EditOrderSection2
        orderable={orderable}
        previousSection={SECTION1}
        nextSection={SECTION3}
        onHide={this.onHide}
        updateSkippedState={(val) =>
          this.setState({ skippedBulkGeneration: val })
        }
        loadTaxRates={loadTaxRates}
        calcTotals={calculateAll}
        navigateToSection={this.navigateToSection}
        updateOrderable={this.updateState}
      />
    )
  }

  renderSection3() {
    const { errors, headquarterId, orderable, loadChefs } = this.props
    const { id, orderableType } = orderable
    const { skippedBulkGeneration, orderSettings } = this.state
    const isProposal = orderableType === 'Proposal'

    return (
      <EditOrderSection3
        errors={errors}
        orderable={orderable}
        orderSettings={orderSettings}
        headquarterId={headquarterId}
        previousSection={
          isProposal && !id && !skippedBulkGeneration ? SECTION2 : SECTION1
        }
        loadChefs={loadChefs}
        renderChef={this.renderChef}
        onSelectChef={this.onSelectChef}
        onSelectSetupDate={this.onSelectSetupDate}
        onChange={this.onChange}
        navigateToSection={this.navigateToSection}
        onDeleteAllChefs={this.onDeleteAllChefs}
        updateServiceAndSupplyCosts={this.updateServiceAndSupplyCosts}
        saveOrderRoles={this.saveOrderRoles()}
        onHide={this.onHide}
        onSave={this.onSave}
      />
    )
  }

  renderNonFinanceEditSection() {
    const { clearError, errors, orderable } = this.props

    return (
      <NonFinanceEditSection
        errors={errors}
        orderable={orderable}
        clearError={clearError}
        onChange={this.onChange}
        onChangePaymentMethod={this.onChangePaymentMethod}
        onHide={this.onHide}
        onSave={this.onSave}
        onSaveContact={this.onSaveContact}
        onSavePaymentMethod={this.onSavePaymentMethod}
        onSelectContacts={this.onSelectContacts}
      />
    )
  }

  renderSections() {
    const { section } = this.state
    if (section === SECTION1) {
      return this.renderSection1()
    } else if (section === SECTION2) {
      return this.renderSection2()
    } else if (section === SECTION3) {
      return this.renderSection3()
    } else if (section === NONFINANCEEDIT) {
      return this.renderNonFinanceEditSection()
    }
  }

  render() {
    const { orderable } = this.props
    const { clientSetUpTime, orderNumber, id, orderableType } = orderable

    const saveOrderRoles = ['sales lead', 'master admin', 'chef lead']
    if (
      orderableType === 'Proposal' ||
      (clientSetUpTime &&
        Moment(clientSetUpTime).add(10, 'days').isAfter(Moment()))
    ) {
      saveOrderRoles.push('sales rep')
    }

    return (
      <Modal
        title={
          id ? `Edit ${orderableType} ${orderNumber}` : `New ${orderableType}`
        }
        hideModal={this.onHide}
        color="#001940"
        width="1300px"
      >
        {this.renderSections()}
      </Modal>
    )
  }
}

EditOrderModal.propTypes = {
  orderable: OrderablePropType,
  headquarterId: PropTypes.number,
  hqLocaleMap: PropTypes.object,

  userPermissions: PropTypes.array,
  user: PropTypes.object,
  accountSettings: PropTypes.object,
  initialBalances: PropTypes.object,

  uri: PropTypes.string,

  section: PropTypes.string,
  hubspotProposal: PropTypes.object,
  hubspotProposals: PropTypes.arrayOf(PropTypes.object),

  errors: PropTypes.object,
  show: PropTypes.bool,

  addCheftoChefs: PropTypes.func,
  addMenuItemToChefs: PropTypes.func,
  addMenuItemsToChefs: PropTypes.func,
  addServiceItemToChefs: PropTypes.func,
  // addMenuItemToOrderItems: PropTypes.func,
  addServiceItemToServiceItems: PropTypes.func,
  addVirtualItemToChefs: PropTypes.func,
  addVirtualKitToChefs: PropTypes.func,
  addSnackPackToChefs: PropTypes.func,
  getChefAmountMap: PropTypes.func,
  calculateAll: PropTypes.func,
  calculateDiscounts: PropTypes.func,
  calcOrderSettings: PropTypes.func,
  clearError: PropTypes.func,
  clearErrors: PropTypes.func,
  clearForm: PropTypes.func,
  close: PropTypes.func,
  delayedUpdateOrder: PropTypes.func,
  displayFailureMessage: PropTypes.func,
  displayWarningMessage: PropTypes.func,
  getDeliveryFeePercentAndLimit: PropTypes.func,
  getEmptyChefs: PropTypes.func,
  loadAccount: PropTypes.func,
  loadAccounts: PropTypes.func,
  loadAccountExecutives: PropTypes.func,
  loadDinerProfiles: PropTypes.func,
  loadChefs: PropTypes.func,
  loadChef: PropTypes.func,
  loadFoodItems: PropTypes.func,
  loadConcepts: PropTypes.func,
  loadConceptsSettings: PropTypes.func,
  loadChefVirtualKits: PropTypes.func,
  loadChefSnackPacks: PropTypes.func,
  loadDinerProfile: PropTypes.func,
  loadOrders: PropTypes.func,
  loadHubspotDeal: PropTypes.func,
  loadHubspotProposals: PropTypes.func,
  loadProposalDashboard: function (props, propName) {
    if (
      props['orderableType'] === 'Proposal' &&
      (props[propName] == undefined || typeof props[propName] != 'function')
    ) {
      return new Error('Please provide a loadProposalDashboard function')
    }
  },
  loadReferralPartners: PropTypes.func,
  loadTaxRates: PropTypes.func,
  pContactKeys: PropTypes.func,
  pDefaultAccountFields: PropTypes.func,
  pOrderAttributesFromDinerProfile: PropTypes.func,
  pRemovedItemFromArray: PropTypes.func,
  pReplacedItemInArray: PropTypes.func,
  pResponseCustomOrderSettings: PropTypes.func,
  predictServiceCosts: PropTypes.func,
  pAccountSettingsFromAccount: PropTypes.func,
  replaceChefNoteInAddOnChefs: PropTypes.func,

  reorderItemInChefs: PropTypes.func,
  reorderChef: PropTypes.func,
  replaceOrderMenuItemInChefs: PropTypes.func,
  replaceOrderServiceItemInChefs: PropTypes.func,
  replaceOrderVirtualItemInChefs: PropTypes.func,
  replaceOrderVirtualKitInChefs: PropTypes.func,
  replaceOrderSnackPackInChefs: PropTypes.func,
  removeOrderMenuItemFromChefs: PropTypes.func,
  removeOrderServiceItemFromChefs: PropTypes.func,
  removeOrderVirtualItemFromChefs: PropTypes.func,
  removeOrderVirtualKitFromChefs: PropTypes.func,
  removeOrderSnackPackFromChefs: PropTypes.func,
  removeChefFromChefs: PropTypes.func,

  saveAccount: PropTypes.func,
  saveAddress: PropTypes.func,
  saveContact: PropTypes.func,
  savePaymentMethod: PropTypes.func,
  saveOrder: PropTypes.func,
  showConfirmationModal: PropTypes.func,
  syncChildItems: PropTypes.func,
  updateOrderFromSettings: PropTypes.func,
  updateTaxExemptStatus: PropTypes.func,
  updateHubspotProposalRef: PropTypes.func,
  validateAddress: PropTypes.func,
  validateContact: PropTypes.func,
  validatePaymentMethod: PropTypes.func,
  validateChefPickupTimes: PropTypes.func,
  confirmPaymentMethod: PropTypes.func,
}

EditOrderModal.defaultProps = {
  loadHubspotDeal: () => undefined, // TODO someone forgot to add this to the containers
  hideModal: PropTypes.func,
  orderServiceCost: {},
  originalMenuItemIds: [],
  originalServiceItemIds: [],
  originalVirtualItemIds: [],
  originalVirtualKitIds: [],
  originalSnackPackIds: [],
}

export default EditOrderModal
