import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { TechHelpForm } from '@components/common'
import TooltipModal from '@components/common/modal/TooltipModal'
import { Label, OrderFormSectionTitle } from '@res/styledComponents/index'
import { AutocompleteInput } from '@containers/common/form'
import { FlexContainer, XSpacing, YSpacing } from '@components/common'
import DividerLine from '@components/common/DividerLine'
import {
  AccountSection,
  AddressSection,
  DinerProfilesSection,
  ContactsSection,
  PaymentSection,
} from '@containers/order/edit/sections'
import { OrderInfoSection } from './sections'
import LinkText from '@components/common/form/LinkText'
import { AuthorizedInteractable } from '@containers/common/auth'
import Button from '@components/common/form/Button'
import { colors } from '../../../../constants'
import { OrderablePropType } from './propTypes'
import { SwitchSelector, TextInput } from '@components/common/form'

const EventSwitchOptions = [
  { label: '✕', value: false, selectedBackgroundColor: colors.gray200 },
  { label: '✔', value: true, selectedBackgroundColor: colors.green },
]

const EditOrderSection1 = (props) => {
  const {
    orderable,

    changePortions,
    loadAccountExecutives,
    loadHubspotProposals,
    renderReferralDropDown,
    skippedBulkGeneration,

    onChange,
    onChangeAccountExecutive,
    onSelectSetupDate,
    onChangeAddress,
    onChangePaymentMethod,
    onCheckCarbonNeutral,
    onHide,
    onSaveAccount,
    onSaveAddress,
    onSaveContact,
    onSavePaymentMethod,
    onSelectAccount,
    onSelectContacts,
    onSelectDinerProfile,
    onSelectHubspotProposal,
    onSelectOrderType,
    onSave,

    clearError,
    errors,
    saveOrderRoles,

    confirmPaymentMethod,

    customCreateSection,
    nextSection,
    navigateToSection,

    showConfirmationModal,

    validateEventServiceItems,
    updateSkippedState,
    updateOrderSettings,

    loadOrderSettings,
  } = props

  const {
    account,
    accountExecutive,
    accountSettings,
    beoLink,
    billingAddress,
    carbonNeutral,
    cateringContact,
    chargeDate,
    clientSetUpTime,
    clientDoNotArriveBeforeTime,
    deliveryInstructions,
    dinerProfile,
    dropoffAddress,
    hasNetPaymentSettings,
    headCount,
    id,
    invoiceContact,
    isVCX,
    hubspotProposal,
    needsCleanup,
    needsStaffing,
    newBillingAddress,
    newCateringContact,
    newDropoffAddress,
    newInvoiceContact,
    newPaymentMethod,
    newReceiptContact,
    orderType,
    orderableType,
    paymentMethod,
    purchaseOrderNumber,
    receiptContact,
    serviceNotes,
    serviceType,
    setUpInstructions,
    setUpStyle,
  } = orderable

  const {
    addresses,
    contacts,
    clientNotes,
    cuisinePreferences,
    clientSuccessMetrics,
    paymentMethods,
  } = account || {}
  const hasAccount = !!account

  const [orderSettings, setOrderSettings] = useState({})

  useEffect(() => {
    const loadSettings = async () => {
      const settings = await loadOrderSettings()
      setOrderSettings(settings)
    }
    loadSettings()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const allowedOrderTypes = () => {
    const { allOrderTypes } = orderSettings
    if (!allOrderTypes) {
      return []
    }

    if (id && orderType === 'Event') {
      return [
        {
          text: 'Event',
          value: 'Event',
        },
      ]
    }

    const isOrder = orderableType === 'Order'

    return allOrderTypes.filter(({ value: orderType }) => {
      if (id && orderType === 'Event') {
        return false
      }
      if (!isOrder && (orderType === 'VCX' || orderType === 'Snack Pack')) {
        return false
      }

      return true
    })
  }

  const [nonEventOrderType, setNonEventOrderType] = useState(
    orderType || 'Lunch',
  )

  const navigateToNextSection = async () => {
    const confirmedPaymentMethod = await confirmPaymentMethod(orderable)
    if (!confirmedPaymentMethod) {
      return
    }
    navigateToSection(nextSection)
  }

  const skipToCustomCreate = async () => {
    updateSkippedState(true)
    navigateToSection(customCreateSection)
  }

  const confirmEvent = () => {
    showConfirmationModal({
      text: 'Are you sure you want to convert this order into an Event order?',
    }).then((confirmed) => {
      if (!confirmed) {
        return
      }

      onSelectOrderType('Event')
      validateEventServiceItems()
    })
  }

  const infoOnSelectOrderType = (orderType) => {
    if (orderType === 'Event') {
      confirmEvent()

      return
    }
    onSelectOrderType(orderType)
    setNonEventOrderType(orderType)
  }

  return (
    <div>
      <div className="flex flex-row w-full justify-between">
        <OrderFormSectionTitle>
          <span>1</span>Enter Your Client Info
        </OrderFormSectionTitle>
        <SwitchSelector
          disabled={!!id}
          label="Switch to Event Proposal Template"
          tooltip={
            <TooltipModal
              unicode="&#9432;"
              width="400px"
              spanMarginRight="75px"
              information="When this order is switched to an Event Order, a customized proposal template with event branding and specific terms will be generated. You will also be able to add a Banquet Event Order (BEO) link, ensuring it appears on the Operations Dashboard for captains to access at the event."
            />
          }
          value={orderType === 'Event'}
          onChange={(isEvent) =>
            isEvent ? confirmEvent() : onSelectOrderType(nonEventOrderType)
          }
          width="300px"
          options={EventSwitchOptions}
        />
      </div>
      <YSpacing height="20px" />
      <FlexContainer width="100%" justifyContent="space-between">
        <AutocompleteInput
          label="Sales Rep"
          width="31%"
          loaderFunction={loadAccountExecutives(orderType == 'VCX')}
          value={(accountExecutive && accountExecutive.name) || ''}
          onSelect={onChangeAccountExecutive}
        />
        <AccountSection
          width="31%"
          onChange={onChange}
          onSave={onSaveAccount}
          account={account}
          onSelectAccount={onSelectAccount}
        />
        {account ? (
          <DinerProfilesSection
            width="31%"
            account={account}
            profile={dinerProfile}
            onSelectProfile={onSelectDinerProfile}
            onClearDinerProfile={() =>
              onChange(
                {
                  dinerProfileId: null,
                  dinerProfile: null,
                  dinerProfileSettings: null,
                },
                () =>
                  updateOrderSettings({
                    clientSetUpTime,
                    accountSettings,
                  }),
              )
            }
          />
        ) : (
          <div style={{ width: '31%' }} />
        )}
      </FlexContainer>
      <YSpacing height="20px" />
      {orderableType === 'Order' && id ? (
        <>
          {renderReferralDropDown()}
          <YSpacing height="20px" />
        </>
      ) : null}
      <YSpacing height="20px" />
      <FlexContainer width="100%" justifyContent="space-between">
        {!isVCX && (
          <AddressSection
            addresses={addresses}
            label={'Dropoff Address'}
            dropoffAddress={dropoffAddress}
            errors={errors}
            hasAccount={hasAccount}
            newDropoffAddress={newDropoffAddress}
            onChange={onChangeAddress('dropoffAddress')}
            onSave={onSaveAddress('dropoffAddress')}
            clearError={clearError}
            width="31%"
          />
        )}
        <AddressSection
          addresses={addresses}
          label={'Billing Address'}
          dropoffAddress={billingAddress}
          errors={errors}
          hasAccount={hasAccount}
          newDropoffAddress={newBillingAddress}
          onChange={onChangeAddress('billingAddress')}
          onSave={onSaveAddress('billingAddress')}
          clearError={clearError}
          width="31%"
        />
        <PaymentSection
          paymentMethod={paymentMethod}
          paymentMethods={paymentMethods}
          errors={errors}
          hasAccount={hasAccount}
          newPaymentMethod={newPaymentMethod}
          onChange={onChangePaymentMethod}
          onSave={onSavePaymentMethod}
          clearError={clearError}
          width="31%"
        />
      </FlexContainer>
      <YSpacing height="20px" />
      <ContactsSection
        contacts={contacts}
        invoiceContact={invoiceContact}
        receiptContact={receiptContact}
        cateringContact={cateringContact}
        errors={errors}
        isVCX={isVCX}
        hasAccount={hasAccount}
        newCateringContact={newCateringContact}
        newInvoiceContact={newInvoiceContact}
        newReceiptContact={newReceiptContact}
        onChange={onSelectContacts}
        onSave={onSaveContact}
        clearError={clearError}
      />
      <OrderInfoSection
        allOrderTypes={allowedOrderTypes()}
        allSetUpStyles={orderSettings.allSetUpStyles || []}
        allServiceTypes={orderSettings.allServiceTypes || []}
        chargeDate={chargeDate}
        clientSetUpTime={clientSetUpTime}
        clientDoNotArriveBeforeTime={clientDoNotArriveBeforeTime}
        deliveryInstructions={deliveryInstructions}
        errors={errors}
        orderableType={orderableType}
        hasNetPaymentSettings={hasNetPaymentSettings}
        headCount={headCount}
        orderType={orderType}
        orderTypeDisabled={!!id && orderType === 'Event'}
        purchaseOrderNumber={purchaseOrderNumber}
        setUpInstructions={setUpInstructions}
        serviceType={serviceType}
        setUpStyle={setUpStyle}
        onChange={onChange}
        changePortions={changePortions}
        onSelectOrderType={infoOnSelectOrderType}
        onCheckCarbonNeutral={onCheckCarbonNeutral}
        serviceNotes={serviceNotes}
        carbonNeutral={carbonNeutral}
        needsCleanup={needsCleanup}
        needsStaffing={needsStaffing}
        clientNotes={clientNotes}
        cuisinePreferences={cuisinePreferences}
        clientSuccessMetrics={clientSuccessMetrics}
        onSelectSetupDate={onSelectSetupDate}
      />

      {orderableType == 'Proposal' && (
        <div>
          <YSpacing height="20px" />
          <AutocompleteInput
            width="31%"
            label="Link to Hubspot Proposal"
            value={(hubspotProposal && hubspotProposal.name) || ''}
            loaderFunction={loadHubspotProposals}
            onSelect={onSelectHubspotProposal}
          />
        </div>
      )}
      {orderType === 'Event' && (
        <>
          <YSpacing height="20px" />
          <Label>Link Event Planning Packet / Banquet Event Order (BEO)</Label>
          <TextInput
            width="62%"
            placeholder="Copy and paste your Google Drive link here for it to appear on the Operations Dashboard"
            value={beoLink}
            onChange={(e) => {
              e.preventDefault()
              onChange({
                beoLink: e.target.value,
              })
            }}
          />
        </>
      )}
      <DividerLine margin="30px 0" />
      <YSpacing height="20px" />
      <FlexContainer alignItems="center" justifyContent="flex-end">
        <TechHelpForm margin="0 30px 0 0" />
        <LinkText
          testId="cancel"
          label="Cancel"
          onClick={onHide}
          color={colors.violet}
        />
        {id && (
          <Fragment>
            <XSpacing width="20px" />
            <AuthorizedInteractable roles={saveOrderRoles}>
              <Button
                testId="save-order"
                label={`Save ${orderableType}`}
                onClick={onSave}
              />
            </AuthorizedInteractable>
          </Fragment>
        )}
        <XSpacing width="20px" />
        {orderableType === 'Proposal' && !id && !skippedBulkGeneration && (
          <>
            <AuthorizedInteractable roles={saveOrderRoles}>
              <Button
                testId="skip-to-create"
                label="Create Custom Proposal"
                onClick={skipToCustomCreate}
              />
            </AuthorizedInteractable>
            <XSpacing width="20px" />
          </>
        )}
        <AuthorizedInteractable roles={saveOrderRoles}>
          <Button
            testId="next-section"
            label={`${
              orderableType == 'Proposal' && !id && !skippedBulkGeneration
                ? 'Bulk Generate Proposals'
                : `${id ? 'Update' : 'Create'} Custom ${orderableType}`
            } 〉`}
            onClick={navigateToNextSection}
          />
        </AuthorizedInteractable>
      </FlexContainer>
    </div>
  )
}

EditOrderSection1.propTypes = {
  orderable: OrderablePropType,
  errors: PropTypes.object,
  saveOrderRoles: PropTypes.array,
  skippedBulkGeneration: PropTypes.bool,

  nextSection: PropTypes.string,
  navigateToSection: PropTypes.func,
  customCreateSection: PropTypes.string,

  clearError: PropTypes.func,
  changePortions: PropTypes.func,
  loadAccountExecutives: PropTypes.func,
  loadHubspotProposals: PropTypes.func,
  renderReferralDropDown: PropTypes.func,
  confirmPaymentMethod: PropTypes.func,

  showConfirmationModal: PropTypes.func,

  validateEventServiceItems: PropTypes.func,

  onChange: PropTypes.func,
  onChangeAccountExecutive: PropTypes.func,
  onSelectSetupDate: PropTypes.func,
  onChangeAddress: PropTypes.func,
  onChangePaymentMethod: PropTypes.func,
  onCheckCarbonNeutral: PropTypes.func,
  onHide: PropTypes.func,
  onSaveAccount: PropTypes.func,
  onSaveAddress: PropTypes.func,
  onSaveContact: PropTypes.func,
  onSelectDinerProfile: PropTypes.func,
  onSavePaymentMethod: PropTypes.func,
  onSelectAccount: PropTypes.func,
  onSelectContacts: PropTypes.func,
  onSelectHubspotProposal: PropTypes.func,
  onSelectOrderType: PropTypes.func,
  onSave: PropTypes.func,
  updateSkippedState: PropTypes.func,
  updateOrderSettings: PropTypes.func,
  loadOrderSettings: PropTypes.func,
}

export default EditOrderSection1
