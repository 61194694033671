import React, { useState, useEffect } from 'react'
import { default as Switch } from 'react-switch-selector'
import { colors } from '@constants'

interface SwitchSelectorOption {
  label: string
  value: string | number | boolean
  color?: string
  selectedBackgroundColor?: string
}

interface SwitchSelectorProps {
  options: SwitchSelectorOption[]
  value?: string | number | boolean
  label?: string
  disabled?: boolean
  tooltip?: React.ReactNode
  onChange: (selectedOptionValue: string | number | boolean) => void
}

const SwitchSelector: React.FC<SwitchSelectorProps> = ({
  options,
  value,
  label,
  disabled,
  tooltip,
  onChange,
}) => {
  const [backgroundColor, setBackgroundColor] = useState<string>(colors.orange)
  useEffect(() => {
    const selectedOption = options.find(({ value: v }) => v === value)
    if (selectedOption && selectedOption.selectedBackgroundColor) {
      setBackgroundColor(selectedOption.selectedBackgroundColor)
    }
  }, [value, options])

  const selectedOption = options.findIndex(({ value: v }) => v === value)

  const onChangeOption: <T>(selectedOptionValue: T | unknown) => void = (
    selectedOptionValue,
  ) => {
    onChange(selectedOptionValue as string | number | boolean)
  }

  return (
    <div className="flex flex-row">
      {label && (
        <div className="flex items-center">
          <span className="mr-2">{label}</span>
          {tooltip && <div className="mt-5 mx-2">{tooltip}</div>}
        </div>
      )}
      <div style={{ width: '50px', height: 25 }}>
        <style>
          {`
            .react-switch-selector-option-unselected {
              opacity: 0.5;
            }
            ${
              !disabled
                ? `.react-switch-selector-option-unselected:hover {
              border: 1px solid black;
              opacity: 1.0;
              transition: 0.3s;
            }`
                : ''
            }
          `}
        </style>
        <Switch
          options={options.map(({ label, value, color }) => ({
            label,
            value,
            selectedBackgroundColor: color || 'white',
          }))}
          disabled={disabled}
          onChange={onChangeOption}
          forcedSelectedIndex={selectedOption}
          initialSelectedIndex={selectedOption}
          backgroundColor={backgroundColor}
          selectionIndicatorMargin={3}
        />
      </div>
    </div>
  )
}

export default SwitchSelector
